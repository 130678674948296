@import "../Constants/color.scss";

.modal-open .modal {
  display: flex;
  align-items: center;
}
.modal-header {
  padding: 10px 10px 0px 10px;
}
.modal-title {
  font-size: 18px !important;
}
.modal-body {
  height: calc(100% - 5px);
  padding-bottom: 50px;
}
.MuiExpansionPanelDetails-root {
  display: block !important;
  padding: 0px !important;
}
.MuiExpansionPanelSummary-root.Mui-expanded {
  min-height: 48px !important;
  background: #8080804a;
}
.MuiExpansionPanelSummary-content.Mui-expanded {
  margin: 0px !important;
}
.root {
  width: 100%;
}

.fa-arrow-up {
  color: $green;
  margin-bottom: 5px !important;
}
.fa-arrow-down {
  color: $red;
}

#lineChart {
  position: relative;
  .y-axis {
    transform: rotate(-90deg);
    position: absolute;
    left: 0px;
    top: 40%;
  }
  .grid {
    stroke: rgba(79, 72, 72, 0.25882352941176473) !important;
  }
}
.x-axis {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  margin-top: -10px;
}

.heading {
  font-size: 13px !important;
  flex-basis: 33.33%;
  flex-shrink: 0;
  color: black;
}

.initial-container {
  position: relative;
  margin-bottom: -6px;
  .initial {
    width: 100%;
    position: absolute;
    font-size: 25px;
    margin-left: 2px;
    margin-top: 12px;
    font-weight: 600;
  }
}

.MuiDrawer-paperAnchorLeft {
  background-color: $darkPrimary !important;
}
.MuiDrawer-paperAnchorRight {
  background-color: $darkPrimary !important;
}

.messageSideBar {
  width: 350px;
  h4 {
    width: 100%;
    position: fixed;
    background: $darkOrange;
    font-size: 18px !important;
    color: white;
    margin-top: 0px;
    padding: 15px;
    margin-bottom: 0px;
    border-bottom: 2px solid;
  }
  .messages {
    margin-top: 60px;
    .message-box {
      position: relative;
      margin: 15px;
      margin-left: 25px;
      background: $primaryColor;
      border-radius: 10px;
      z-index: -1;
      .information {
        color: #ecb4e7;
        padding-bottom: 0px;
      }
      p {
        margin: 0px 15px;
        padding: 10px 0px;
        color: whitesmoke;
        margin-bottom: 0px;
        color: whitesmoke;
        font-size: 12px;
      }
      .triangle-left {
        width: 0;
        height: 0;
        border-top: 15px solid transparent;
        border-right: 21px solid $primaryColor;
        border-bottom: 0px solid transparent;
        position: absolute;
        bottom: 0px;
        left: -13px;
      }
    }
    .msg-loader {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 25px;
        margin-bottom: 12px;
      }
      span {
        font-size: 14px;
        color: $white;
        font-weight: 600;
        margin-right: 5px;
        margin-bottom: 15px;
      }
    }
  }
}


.sideBar {
  width: 300px;
  h4 {
    font-size: 18px !important;
    color: white;
    // margin-top: 20px;
    // margin-bottom: 0px;
    // padding-bottom: 10px;
    // padding-left: 15px;
    
  }
  .menu-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 2px solid #fff;

  .menu-close{
    font-size: 28px !important;
    color: white;
    // margin-top: 20px;
    // margin-bottom: 0px;
    // padding-bottom: 10px;
    // padding-right: 15px;
    cursor: pointer;
  }
}
  p {
    padding: 15px;
    color: whitesmoke;
  }
  a {
    text-decoration: none;
    color: $white;
  }
  a:hover {
    text-decoration: none;
  }
  .MuiList-padding {
    padding: 5px;
  }
  .MuiTreeItem-content {
    border-bottom: solid 0.5px $grayBorderColor;
    flex-direction: row-reverse;
  }
  .MuiTreeItem-content:hover {
    background-color: #2e3248;
  }
  .Mui-expanded .MuiTreeItem-content:hover {
    background-color: #2e3248;
  }
  .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
    background-color: transparent;
  }
  .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover,
  .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label {
    background-color: transparent;
  }
  .MuiTreeItem-iconContainer svg {
    font-size: 24px;
    color: white;
  }
  .MuiTreeItem-label {
    font-size: 14px;
    padding: 10px;
    color: white;
  }
}

.notification {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 111;
  .fa-bell-o {
    font-size: 26px;
    margin: 0px 5px;
    cursor: pointer;
  }
  .circle-bell {
    position: absolute;
    background: red;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    right: 9px;
  }
}

.notification-dropdown {
  width: 300px;
  background-color: #141927;
  position: absolute;
  top: 52px;
  right: 0;
  animation: fadein ease 0.2s;
  .notification-top {
    background-color: #1f2436;
    padding: 12px;
    letter-spacing: 0.6px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .count {
      color: $orange;
      margin-right: 10px;
      font-weight: 800;
    }
    .fa-close {
      font-size: 16px;
      cursor: pointer;
    }
  }
  .notifications {
    height: 250px;
    overflow: auto;
    padding-bottom: 15px;
    padding-top: 15px;
    .notification {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px 15px 10px 15px;
      height: 50px;
      border-bottom: 0.5px solid $grayBorderColor;
      margin-bottom: 15px;
      .heading {
        color: rgba(255, 255, 255, 0.4);
        margin-bottom: 3px;
      }
      .actions {
        color: rgba(255, 255, 255, 0.4);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        .red {
          color: $red;
        }
        .green {
          color: $green;
        }
      }
    }
    .ellipsis-loader {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    .bottom {
      color: rgba(255, 255, 255, 0.4);
      display: flex;
      justify-content: center;
      font-style: inherit;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.profile-dropdown {
  position: absolute;
  background-color: $darkPrimary !important;
  top: 52px;
  right: 0;
  .MuiSvgIcon-root {
    color: $white;
  }
  a {
    text-decoration: none;
    color: $white;
    padding: 0px 60px;
    margin: 0px -60px;
  }
  a:hover {
    text-decoration: none;
  }
}

.circle {
  width: 50px !important;
  height: 50px !important;
  color: #e0edfb;
  margin-left: 10px;
  margin-right: 5px;
}

.close-btn {
  width: 30px !important;
  height: 30px !important;
  color: #e0edfb;
  margin-left: 10px;
  margin-right: 5px;
}

.date-picker-btn {
  background-color: $darkPrimary;
  color: $white;
  border: none;
  margin-bottom: 0px;
  width: auto;
  font-size: 0.9vw;
}

.triangle-component {
  display: flex;
  justify-content: center;
  .triangle-up {
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 11px solid #eff2f7;
    position: absolute;
    top: 43px;
    animation: fadein ease 0.2s;
  }
}

.date-range-picker-center {
  top: 0px;
  align-items: center;
  height: 100vh !important;
  // background: #00000073;
}

.dateRangePicker {
  height: 450px;
  position: absolute;
  top: 52px;
  right: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  z-index: 1111;
  .rdrDateRangePickerWrapper {
    position: relative;
    opacity: 1;
    animation: fadein ease 0.2s;
  }
  .rdrDefinedRangesWrapper {
    width: 185px !important;
  }
  .rdrCalendarWrapper {
    width: 570px !important;
  }
  .rdrStaticRangeLabel {
    color: black;
  }
  .rdrInputRange {
    color: black;
  }
  .btn-container {
    position: relative;
    display: flex;
    // height: 100vh;
    align-items: center;
    .select-date-btn {
      opacity: 1;
      animation: fadein ease 0.2s;
      background: #3d91ff;
      position: absolute;
      bottom: 25px;
      z-index: 1;
      margin-left: 22px;
      outline: none;
      width: 70px;
      padding: 8px;
      border: none;
      border-radius: 20px;
    }
  }
  .text-container {
    position: relative;
    display: flex;
    .text {
      position: absolute;
      color: #849095;
      z-index: 1;
      width: 100px;
    }
  }
}

.pagination-main {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  font-size: 12px;
  span {
    margin: 0px 5px;
  }
  .button-span {
    cursor: pointer;
  }
  .disabled {
    pointer-events: none;
  }
}

.auto-select {
  .css-2b097c-container {
    width: 170px;
  }
  .css-yk16xz-control {
    background-color: $darkPrimary !important;
    border: solid 1px rgba(255, 255, 255, 0.23);
    border-radius: inherit;
  }
  .css-yk16xz-control:hover {
    border: solid 1px rgba(255, 255, 255, 0.23);
  }
  .css-1pahdxg-control {
    background-color: $darkPrimary !important;
    border: solid 1px rgba(255, 255, 255, 0.23);
    box-shadow: none;
    border-radius: inherit;
  }
  .css-1pahdxg-control:hover {
    border: solid 1px rgba(255, 255, 255, 0.23);
  }
  .css-1wa3eu0-placeholder {
    color: $white;
  }
  .css-1gtu0rj-indicatorContainer {
    color: $white;
  }
  .css-1gtu0rj-indicatorContainer:hover {
    color: $white;
  }
  .css-4ljt47-MenuList {
    background-color: $darkPrimary !important;
    color: $white;
  }
  .css-26l3qy-menu {
    background: $darkPrimary !important;
    border-radius: 0px;
  }
  .css-1n7v3ny-option {
    background-color: $primaryColor !important;
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-b8ldur-Input {
    color: $white;
  }
  .css-1uccc91-singleValue {
    color: $white;
  }
}

.full-width-card {
  width: 100% !important;
}

.cards-modal {
  height: 500px;
  overflow: auto;
}

.alert-card-main {
  position: relative;
  h5 {
    font-size: 18px;
    color: $redText;
    z-index: 111;
  }
  .action {
    position: absolute;
    font-size: 18px;
    color: $white;
    z-index: 1;
    right: 20px;
    top: 2px;
  }
  .card {
    color: white;
    background: $header;
    border: 0.5px solid $lightGray;
    border-radius: 1px;
    margin-bottom: 10px;
    padding: 15px;
    .heading {
      color: white;
      border-bottom: 0.5px solid;
      padding-bottom: 5px;
      margin-bottom: 10px;
      font-size: 15px !important;
    }
    .details {
      margin-bottom: 10px;
      ul {
        list-style: none;
        margin-bottom: 5px;
        padding-left: 0px;
        letter-spacing: 0.4px;
        line-height: 22px;
      }
      .red {
        color: $red;
      }
      .green {
        color: $green;
      }
    }
    .users-initials {
      h5 {
        font-size: 13px;
        letter-spacing: 0.3px;
      }
      .users-initials-main {
        display: flex;
        flex-flow: wrap;
        .users-circle {
          width: 30px;
          height: 30px;
          background: whitesmoke;
          border-radius: 15px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          margin-right: 4px;
          margin-bottom: 4px;
        }
        .initial {
          color: $darkPrimary;
          font-size: 22px;
          font-weight: 700;
          text-transform: uppercase;
        }
      }
    }
    .card-btn {
      .btns {
        margin-bottom: 10px;
        margin-right: 10px;
        background: $darkOrange;
        width: 120px;
        outline: none;
        border: none;
        padding: 6px;
        border-radius: 2px;
      }
    }
  }
}

.fade-card {
  opacity: 0.5;
  pointer-events: none;
  width: 100%;
}
.hide-card {
  display: none;
}

.change-password {
  width: 150px;
  img {
    width: 18px;
  }
}

.sidebarIcon {
  .MuiSvgIcon-root {
    width: 28px !important;
    height: 28px !important;
  }
}

.messageIcon {
  padding: 0px !important;
  position: absolute !important;
  right: 0px;
  .MuiSvgIcon-root {
    width: 40px !important;
    height: 40px !important;
    color: $orange;
  }
  .circle-message {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    background: red;
    width: 30px;
    height: 30px;
    border-radius: 15px;
    right: 25px;
    top: -10px;
    .count {
      font-size: 12px;
    }
  }
}

.regions-dropdown {
  display: flex;
  align-items: center;
  margin-left: 10px;
  .disable-main {
    cursor: not-allowed;
    .disable {
      pointer-events: none;
      .MuiSelect-selectMenu {
        cursor: not-allowed;
      }
    }
    .MuiInputLabel-formControl {
      cursor: not-allowed;
    }
  }
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    width: 125px;
    padding-right: 25px;
    height: 37px;
    max-width: 600px;
    max-height: 40px;
    margin-left: 6px;
    overflow-x: auto;
    padding-top: 1px;
    border: solid 1px rgba(255, 255, 255, 0.23) !important;
    border-radius: 0;
    flex-direction: column;
  }
  .MuiAutocomplete-endAdornment{
    display: none;
  }
  .MuiSelect-select.MuiSelect-select:focus {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  }
  .MuiInputLabel-formControl {
    z-index: 11;
    color: white;
    font-size: 0.9vw;
    top: -8px;
    left: 16px;
    margin-top: -5px;
  }
  .MuiInputLabel-shrink {
    transform: translateY(24px);
  }
  .MuiFormLabel-root.Mui-focused {
    color: $white;
  }
  label + .MuiInput-formControl {
    margin-top: 0px;
    margin-right: 5px;
    border: solid 1px rgba(255, 255, 255, 0.23) !important;
    border-radius: 0;
    border: none;
    margin-left: 5px;
    color: white;
    background-color: #2e303f;
    box-shadow: inset 0 1px 1px transparent;
  }
  .MuiSelect-selectMenu {
    height: 15px;
    font-size: 14px;
    padding-top: 11px;
    padding-left: 5px;
    font-size: 0.9vw !important;
  }
  .MuiSelect-icon {
    color: $white;
    top: 10px;
    right: 2px;
  }
  .MuiAutocomplete-popupIndicator {
    margin-right: -8px;
    margin-top: 4px;
    color: white;
  }
 
  #checkboxes-tags-demo::placeholder{
    color: white;
    opacity: 1.5;
    font-family: "Helvetica Neue", Roboto, Arial, "Droid Sans", sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.471;
  }

  .MuiInput-underline:before {
    display: none;
  }
  .MuiInput-underline:after {
    display: none;
  }
}

#checkboxes-tags-demo {
    color: white;
    font-size: 15px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 520;
    margin-top: -2px;
    margin-right: 50px;
}

.hide-regions-dropdown-label {
  .MuiInputLabel-shrink {
    display: none;
  }
}

.MuiMenu-paper {
  .MuiListItemText-root {
    .MuiTypography-root {
      font-size: 0.9vw !important;
    }
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-height: 580px) {
  .date-picker-btn {
    width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 9px;
  }
}

@media (max-width: 1180px) {
  .date-picker-btn {
    width: 170px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 9px;
  }
}

.month-picker {
  position: relative;
  width: auto !important;
  max-width: 195px !important;
}

.month-picker {
  .box {
    border: solid 1px rgba(255, 255, 255, 0.23) !important;
    background-color: $darkPrimary !important;
    padding-left: 0.9rem;
    box-sizing: border-box;
    height: 35px;
    padding: 6px;
    padding-top: 0px;
    margin-right: 4px;
    overflow: hidden;
    label {
      color: $white !important;
      font-weight: normal !important;
      cursor: pointer;
      font-size: 0.9vw !important;
    }
  }
}

.month-picker > .rmp-container {
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 100;
  top: 1px;
  left: -10000px;
  -webkit-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
  -moz-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
  transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
}

@media screen and (max-width: 767px) {
  .month-picker > .rmp-container {
    position: fixed;
    top: 0;
    left: -10000px;
    width: 100%;
    height: 100%;
    -webkit-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
    -moz-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
    transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms;
  }
}

.month-picker > .rmp-container.rmp-table {
  display: table;
}

.month-picker > .rmp-container.show {
  left: 0;
  -webkit-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  -moz-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.month-picker > .rmp-container.show .rmp-overlay {
  left: 0;
}

.month-picker > .rmp-container .rmp-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 9;
  top: 0;
  left: -10000px;
  opacity: 1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: opacity;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  -moz-transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

@media screen and (max-width: 767px) {
  .month-picker > .rmp-container .rmp-overlay {
    background-color: rgba(0, 0, 0, 0.25);
  }
}

.month-picker > .rmp-container .rmp-cell {
  display: table-cell;
  vertical-align: middle;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 767px) {
  .month-picker > .rmp-container .rmp-cell {
    vertical-align: bottom;
  }
}

.month-picker > .rmp-container .rmp-popup {
  position: absolute;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
  margin: 0 auto;
  z-index: 10;
  font-size: 1.2rem;
  opacity: 0;
  border-radius: 3px;
  padding: 0.4rem;
  box-sizing: content-box;
}

@media screen and (max-width: 767px) {
  .month-picker > .rmp-container .rmp-popup {
    box-sizing: border-box;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    height: 14.4rem;
    -webkit-transform: translate3d(0, 14.4rem, 0);
    -moz-transform: translate3d(0, 14.4rem, 0);
    -ms-transform: translate3d(0, 14.4rem, 0);
    -o-transform: translate3d(0, 14.4rem, 0);
    transform: translate3d(0, 14.4rem, 0);
  }

  .month-picker > .rmp-container .rmp-popup.range {
    height: 28rem;
    -webkit-transform: translate3d(0, 28rem, 0);
    -moz-transform: translate3d(0, 28rem, 0);
    -ms-transform: translate3d(0, 28rem, 0);
    -o-transform: translate3d(0, 28rem, 0);
    transform: translate3d(0, 28rem, 0);
  }

  .month-picker > .rmp-container .rmp-popup.range .rmp-pad {
    margin-top: 0.4rem;
  }

  .month-picker > .rmp-container .rmp-popup.range .rmp-pad:first-of-type {
    margin-top: 0;
  }
}

@media screen and (min-width: 768px) {
  .month-picker > .rmp-container .rmp-popup {
    -webkit-transform: translate3d(0, -64px, 0);
    -moz-transform: translate3d(0, -64px, 0);
    -ms-transform: translate3d(0, -64px, 0);
    -o-transform: translate3d(0, -64px, 0);
    transform: translate3d(0, -64px, 0);
    top: 0;
    width: 20rem;
  }

  .month-picker > .rmp-container .rmp-popup.range {
    width: 40.6rem;
    padding: 0.6rem;
  }
}

.month-picker > .rmp-container .rmp-popup.show {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.month-picker > .rmp-container .rmp-popup:after {
  content: " ";
  clear: both;
  display: table;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad {
  position: relative;
}

@media screen and (min-width: 768px) {
  .month-picker > .rmp-container .rmp-popup .rmp-pad {
    box-sizing: border-box;
    float: left;
    width: 20rem;
  }

  .month-picker > .rmp-container .rmp-popup .rmp-pad:nth-of-type(2) {
    float: right;
  }
}

.month-picker > .rmp-container .rmp-popup .rmp-pad > div label {
  display: block;
  font-size: 1.4rem;
  text-align: center;
  line-height: 3.4rem;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad > div label b {
  font-weight: normal;
  margin-right: 0.5em;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad > div i {
  font-style: normal;
  text-align: center;
  width: 3.4rem;
  height: 3.4rem;
  line-height: 3.4rem;
  position: absolute;
  top: 0;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad > div i.prev {
  left: 0;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad > div i.next {
  right: 0;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad ul,
.month-picker > .rmp-container .rmp-popup .rmp-pad li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad ul {
  display: block;
  width: 100%;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad ul:after {
  content: " ";
  clear: both;
  display: table;
}

.month-picker > .rmp-container .rmp-popup .rmp-pad li {
  display: block;
  float: left;
  text-align: center;
  line-height: 3.4rem;
  font-size: 1.15rem;
  border-radius: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  *white-space: nowrap;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
  -moz-transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
}

@media screen and (max-width: 767px) {
  .month-picker > .rmp-container .rmp-popup .rmp-pad li {
    width: 25%;
  }
}

@media screen and (min-width: 768px) {
  .month-picker > .rmp-container .rmp-popup .rmp-pad li {
    width: 33.3333333333%;
  }
}

.month-picker > .rmp-container .rmp-popup.light {
  color: #666;
  background-color: rgba(255, 255, 255, 0.96);
}

@media screen and (max-width: 767px) {
  .month-picker > .rmp-container .rmp-popup.light {
    border-top: 1px solid #ccc;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.08);
  }

  .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad {
    border-top: 1px solid rgba(204, 204, 204, 0.5);
  }

  .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad:first-of-type {
    border-top: 0;
  }
}

@media screen and (min-width: 768px) {
  .month-picker > .rmp-container .rmp-popup.light {
    border: 1px solid #ccc;
    box-shadow: 0 1px 5px #ddd;
  }

  .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad {
    background-color: rgba(238, 238, 238, 0.9);
  }
}

.month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn {
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn:hover {
    background-color: rgba(255, 227, 160, 0.59);
  }
}

.month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn.select {
  background-color: #d3d3d3;
}

.month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active,
.month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active:hover {
  background-color: rgba(31, 42, 58, 0.73);
  color: white;
  cursor: default;
}

.month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable,
.month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable:hover {
  background-color: transparent;
  color: #bbb;
  cursor: default;
}

.month-picker > .rmp-container .rmp-popup.dark {
  color: #fff;
  background-color: rgba(50, 50, 50, 0.96);
}

@media screen and (max-width: 767px) {
  .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad {
    border-top: 1px solid rgba(113, 113, 113, 0.41);
  }

  .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad:first-of-type {
    border-top: 0;
  }
}

@media screen and (min-width: 768px) {
  .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad {
    background-color: rgba(70, 70, 70, 0.9);
  }
}

.month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn {
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn:hover {
    background-color: rgba(255, 210, 96, 0.33);
  }
}

.month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn.select {
  background-color: #262828;
}

.month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.active,
.month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.active:hover {
  background-color: rgba(189, 211, 242, 0.7);
  color: #303030;
  cursor: default;
}

.month-picker > .rmp-container .rmp-popup.dark .rmp-pad .disable,
.month-picker > .rmp-container .rmp-popup.dark .rmp-pad .disable:hover {
  background-color: transparent;
  color: #717171;
  cursor: default;
}

.month-picker > .box {
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .month-picker > .box {
    box-sizing: border-box;
    line-height: 5rem;
    font-size: 1.32rem;
    color: #777777;
    text-align: right;
  }
}

@media screen and (min-width: 768px) {
  .month-picker > .box {
    border: 1px solid #e0e0e0;
    background-color: #f6f6f6;
    padding-left: 0.9rem;
    box-sizing: border-box;
    line-height: 3.2rem;
    font-size: 1.3rem;
    color: #525252;
  }

  .month-picker > .box > label {
    font-size: 1.3rem;
    color: #525252;
  }
}

.month-picker .tab.btn {
  font-size: 0;
}

.month-picker .tab.btn:before {
  font-style: normal;
  font-weight: normal;
  font-family: "icomoon";
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  font-size: 1.8rem;
  line-height: 3.4rem;
  text-align: center;
  width: 3.4rem;
}

.month-picker .tab.btn.prev:before {
  content: "";
}

.month-picker .tab.btn.next:before {
  content: "";
}

.list-area {
  margin-top: 5rem;
}

@media screen and (min-width: 768px) {
  .list-area {
    width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 767px) {
  .list-area > ul {
    border-bottom: 1px solid #e8e8e8;
  }

  .list-area > ul > li {
    position: relative;
    background-color: #fff;
    border-top: 1px solid #e8e8e8;
    padding: 0 1.6rem;
  }

  .list-area > ul > li:after {
    content: " ";
    clear: both;
    display: table;
  }

  .list-area > ul > li > label {
    float: left;
    display: block;
    line-height: 5rem;
    color: #440f24;
    font-size: 1.12rem;
  }

  .list-area > ul > li > label b {
    font-size: 1.1em;
  }

  .list-area > ul > li > label span {
    position: absolute;
    display: block;
    font-size: 0.8rem;
    color: #bfbfbf;
    left: 1.6rem;
    top: 1.5em;
  }

  .list-area > ul > li .edit {
    margin-left: 9rem;
    line-height: 5rem;
  }
}

@media screen and (min-width: 768px) {
  .list-area > ul {
    padding: 1.8rem;
  }

  .list-area > ul > li {
    padding: 0.5rem 0 1.4rem;
    font-size: 1.3rem;
  }

  .list-area > ul > li:after {
    content: " ";
    clear: both;
    display: table;
  }

  .list-area > ul > li > label {
    display: block;
    margin-right: 1rem;
    padding: 1px;
    color: #898989;
    font-size: 1.1rem;
    margin-bottom: 0.5em;
  }

  .list-area > ul > li > label b {
    display: block;
    font-size: 1.1em;
  }

  .list-area > ul > li > label span {
    display: block;
    font-size: 0.9em;
    color: #afafaf;
    margin-top: 0.4em;
  }

  .list-area > ul > li .edit {
    padding: 1px;
    height: 3.3rem;
  }
}



.show-hoverable-legends.hovered .segment circle{opacity:0.2}
.show-hoverable-legends .cursor-hover-legend{cursor: pointer;}
.hidden-texts{visibility: hidden; font-size: 13px; font-weight: 600;}
.show-hoverable-legends .hidden-texts.show{visibility: visible;}

.pos-images table{
  background-color: rgba(42, 63, 84, 0.9098039215686274);
  color: #fff;
}
.pos-images td,th,tr{
  border: 2px solid black;
  padding: 4px;
  font-size: 8px;
}
.t-head{
  background-color: #ff7139;
  color: black;
  border: 2px solid black;
}