@import "../../Constants/color.scss";
/* -------------------------------- 

Primary style

-------------------------------- */
.route-profitabilty-consolidate-page {
    .x_panel {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 10px;
    }
    .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .top {
            .navdesign {
                padding: 12px;
                margin-top: 0px;
            }
            .toggle1 {
                display: flex;
                align-items: center;
                padding: 0px;
                h2 {
                    margin: 0px;
                    font-size: 17px !important;
                }
                section {
                    margin: -10px 0px -13px 0px;
                }
                p {
                    margin: 0px;
                    margin-left: 20px;
                    cursor: pointer;
                }
            }
            .toggle2 {
                float: right;
                display: flex;
                justify-content: flex-end;
            }
            span {
                margin-left: 20px;
                font-size: 14px;
                margin-bottom: -3px;
            }
        }
    }
    h6 {
        margin-left: 4px;
    }

    .cd-breadcrumb {
        display: flex;
        align-items: center;
        padding: 0px;
        font-size: 14px;
        margin: 0px;
    }
    .cd-breadcrumb:after,
    .cd-multi-steps:after {
        content: "";
        display: table;
        clear: both;
    }
    .cd-breadcrumb li,
    .cd-multi-steps li {
        display: inline-block;
        float: left;
        margin: 0.5em 0;
        cursor: pointer;
        min-width: auto;
        max-width: 110px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .tableHeader {
        height: 65px;
        overflow: hidden;
    }
    .tableContent {
        height: calc(100vh - 210px);
        overflow: auto;
    }

    .ag-grid-table .ag-overlay-no-rows-center {
        margin-top: -30px !important;
    }

    .totalRow .ag-overlay-no-rows-center {
        margin-top: 45px !important;
    }
    .spinnertable {
        margin: 20px 0px !important;
    }
    .cost-up {
        color: $red !important;
        margin-left: 3px !important;
    }
    .cost-down {
        color: $green !important;
        margin-left: 3px !important;
    }
}
